import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { stripTags } from '../../../domain/utils/stringUtils';
import { ParagraphWrapper } from '../ParagraphWrapper';

const styles = {
  main: css`
    border: 1px solid #ccc;
    color: #555;
    ${theme.font.family('regularWeb')};
    font-size: ${theme.font.size('base')};
    font-weight: ${theme.font.weight('base')};
    letter-spacing: 0.1px;
    line-height: ${theme.line.height('l')};
    margin: 32px 0;
    overflow: hidden;
    padding: 16px 20px;
  `,
  title: css`
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('base')};
    text-transform: uppercase;
    color: #555;
  `,
  header: css`
    line-height: ${theme.line.height('xl')};
    a:hover {
      background-size: 100% 100%;
      border-color: #fc0;
      color: #000;
    }
    a {
      background-image: linear-gradient(120deg, #fc0, #fc0);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 100% 0;
      border: none;
      border-bottom: 2px solid #fc0;
      color: #555;
      transition: background-size 0.125s ease-in;
    }
  `,
  h3: css`
    list-style: none !important;
    margin-left: ${theme.spacing('xl')};
  `
};

const TableOfContentsParagraphInner = ({ data, content }) => {
  const { title, levelsToList } = data;
  const headers = [];
  const posCounter = { h2: 0, h3: 0 };
  content.mainContent.forEach(paragraph => {
    if (paragraph.entity.type === 'ParagraphContent') {
      const paragraphHeaders = [
        ...paragraph.entity.content.value.matchAll(
          /<h2.*?id="([^"]*?)".*?>(.*?)<\/h2>/gi
        )
      ];
      paragraphHeaders.forEach(header => {
        const type = header[0].includes('class="h3"') ? 'h3' : 'h2';
        if (!(levelsToList === 'h2' && type === 'h3')) {
          let pos;
          if (type === 'h2') {
            posCounter.h2 += 1;
            posCounter.h3 = 0;
            pos = posCounter.h2;
          } else {
            posCounter.h3 += 1;
            pos = `${posCounter.h2}.${posCounter.h3}`;
          }

          headers.push({
            type,
            id: header[1],
            pos,
            text: stripTags(header[2])
          });
        }
      });
    }
  });
  return (
    <div css={styles.main}>
      {title !== '' && <h2 css={styles.title}>{title}</h2>}
      <ul>
        {headers.map(header => (
          <li
            css={
              header.type === 'h3' ? [styles.header, styles.h3] : styles.header
            }
            key={header.id}
          >
            {
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <a
                href={`#${header.id}`}
                data-tocpos={header.pos}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: header.text
                }}
              />
            }
          </li>
        ))}
      </ul>
    </div>
  );
};

TableOfContentsParagraphInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

export const TableOfContentsParagraph = ParagraphWrapper(
  TableOfContentsParagraphInner
);
